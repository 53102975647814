<template>
  <div
    v-if="
      ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.tesouraria == 'S') &&
        $store.state.fastPermissoes.ativo == 'S' &&
        $store.state.fastPlataformaModulos.modulo_secretaria
    "
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                href="#"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                href="#"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/tesouraria')"
              >Modo tesouraria</a>              
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Cadastro Empresa</a>
            </li>
          </ul>
        </nav>

        <!-- /breadcrumb -->
      </div>
      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div
              id="body_conteudo_modo_admin"
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 class="aluno_font_color">
                  Cadastro
                  <span>Empresa</span>
                </h1>

                <img :src="require('@/assets/images/form-effect-01.png')">
              </section>
              <!-- /fd-app-welcome -->
              <div class="row">
                <div class="col-12">
                  <section class="fd-app-opcoes mt-5">
                    <div class="card shadow mb-4">
                      <div class="card-header py-3">
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-6">
                            <h6 class="m-0 font-weight-bold text-primary">
                              Cadastro Empresa
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="row justify-content-between">
                          <div class="col-sm-12 col-md-12 col-lg-3 mb-3">
                            <label>CNPJ *</label>
                              <input
                              v-mask="[
                                '##.###.###/####-##',
                              ]"
                              class="form-control"
                              type="text"
                              autocomplete="no"
                                required
                                      >
                          </div>
                          <div class="col-sm-12 col-md-12 col-lg-3 mb-3">
                            <label>Razão social *</label>
                              <input
                              class="form-control"
                              type="text"
                                required
                            >
                          </div>
                          <div class="col-sm-12 col-md-12 col-lg-3 mb-3">
                            <label>Nome fantasia</label>
                              <input
                              class="form-control"
                              type="text"
                            >
                          </div>
                          <div class="col-sm-12 col-md-12 col-lg-3 mb-3">
                            <label>Regime tributário *</label>
                            <select
                              class="form-control"
                              required
                            >
                              <option>Isento</option>
                              <option>Microempreendedor Individual</option>
                              <option>Simples Nacional</option>
                              <option>Lucro Presumido</option>
                              <option>Lucro Real</option>
                            </select>
                          </div>
                          <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                            <label>Natureza Jurídica *</label>
                              <select
                              class="form-control"
                                required
                              >
                                <option></option>
                                <option>Empresa Publica</option>
                                <option>Sociedade Economia Mista</option>
                                <option>Sociedade Anonima Aberta</option>
                                <option>Sociedade Anonima Fechada</option>
                                <option>Sociedade Empresaria Limitada</option>
                                <option>Sociedade Empresaria Em Nome Coletivo</option>
                                <option>Sociedade Empresaria Em Comandita Simples</option>
                                <option>Sociedade Empresaria Em Comandita por Acões</option>
                                <option>Sociedade em Conta Participacão</option>
                                <option>Empresario</option>
                                <option>Cooperativa</option>
                                <option>Consorcio Sociedades</option>
                                <option>Grupo Sociedades</option>
                                <option>Empresa Domiciliada Exterior</option>
                                <option>Clube Fundo Investimento</option>
                                <option>Sociedade Simples Pura</option>
                                <option>Sociedade Simples Limitada</option>
                                <option>Sociedade Simples Em Nome Coletivo</option>
                                <option>Sociedade Simples Em Comandita Simples</option>
                                <option>Empresa Binacional</option>
                                <option>Consorcio Empregadores</option>
                                <option>Consorcio Simples</option>
                                <option>Eireli Natureza Empresaria</option>
                                <option>Eireli Natureza Simples</option>
                                <option>Servico Notarial</option>
                                <option>Fundacão Privada</option>
                                <option>Servico Social Autonomo</option>
                                <option>Condominio Edilicio</option>
                                <option>Comissão Conciliacão Previa</option>
                                <option>Entidade Mediacão Arbitragem</option>
                                <option>Partido Politico</option>
                                <option>Entidade Sindical</option>
                                <option>Estabelecimento Brasil Fundacão Associacão Estrangeiras</option>
                                <option>Fundacão Associacão Domiciliada Exterior</option>
                                <option>Organizacão Religiosa</option>
                                <option>Comunidade Indigena</option>
                                <option>Fundo Privado</option>
                                <option>Associacão Privada</option>
                            </select>
                          </div>
                          <div class="col-sm-12 col-md-12 col-lg-3 mb-3">
                            <label>Data Abertura Empresa *</label>
                              <input
                                type="date"
                                class="form-control"
                                maxlength="2"
                                autocomplete="no"
                                required
                              >
                          </div>
                          <div class="col-sm-12 col-md-12 col-lg-2 mb-3">
                            <label>CEP</label>
                              <input
                              v-mask="'#####-###'"
                              class="form-control"
                              type="text"
                              autocomplete="no"
                              >
                          </div>
                          <div class="col-sm-12 col-md-12 col-lg-3 mb-3">
                            <label>Logradouro *</label>
                              <input
                              class="form-control"
                              type="text"
                              required
                            >
                          </div>
                          <div class="col-sm-12 col-md-12 col-lg-2 mb-3">
                            <label>Número *</label>
                              <input
                                type="number"
                                class="form-control"
                                maxlength="10"
                                autocomplete="no"
                                required
                              >
                          </div>
                          <div class="col-sm-12 col-md-12 col-lg-3 mb-3">
                            <label>Complemento</label>
                              <input
                                type="text"
                                class="form-control"
                                autocomplete="no"
                                
                              >
                          </div>
                          <div class="col-sm-12 col-md-12 col-lg-3 mb-3">
                            <label>Bairro</label>
                              <input
                                type="text"
                                class="form-control"
                                autocomplete="no"
                              >
                          </div>
                          <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                            <label>Cidade</label>
                              <input
                                type="text"
                                class="form-control"
                                autocomplete="no" 
                              >
                            
                          </div>
                          <div class="col-sm-12 col-md-12 col-lg-2 mb-3">
                            <label>UF</label>
                              <select 
                                class="form-control"
                                >
                                <option></option>
                                <option value="AC">Acre</option>
                                <option value="AL">Alagoas</option>
                                <option value="AP">Amapá</option>
                                <option value="AM">Amazonas</option>
                                <option value="BA">Bahia</option>
                                <option value="CE">Ceará</option>
                                <option value="DF">Distrito Federal</option>
                                <option value="ES">Espírito Santo</option>
                                <option value="GO">Goiás</option>
                                <option value="MA">Maranhão</option>
                                <option value="MT">Mato Grosso</option>
                                <option value="MS">Mato Grosso do Sul</option>
                                <option value="MG">Minas Gerais</option>
                                <option value="PA">Pará</option>
                                <option value="PB">Paraíba</option>
                                <option value="PR">Paraná</option>
                                <option value="PE">Pernambuco</option>
                                <option value="PI">Piauí</option>
                                <option value="RJ">Rio de Janeiro</option>
                                <option value="RN">Rio Grande do Norte</option>
                                <option value="RS">Rio Grande do Sul</option>
                                <option value="RO">Rondônia</option>
                                <option value="RR">Roraima</option>
                                <option value="SC">Santa Catarina</option>
                                <option value="SP">São Paulo</option>
                                <option value="SE">Sergipe</option>
                                <option value="TO">Tocantins</option>
                                <option value="EX">Estrangeiro</option>
                            </select>
                          </div>
                          
                          <div class="col-sm-12 col-md-12 col-lg-2 mb-3">
                            <label>País</label>
                              <select class="form-control">
                                <option value="Brasil" selected="selected">Brasil</option>
                                <option value="Afeganistão">Afeganistão</option>
                                <option value="África do Sul">África do Sul</option>
                                <option value="Albânia">Albânia</option>
                                <option value="Alemanha">Alemanha</option>
                                <option value="Andorra">Andorra</option>
                                <option value="Angola">Angola</option>
                                <option value="Anguilla">Anguilla</option>
                                <option value="Antilhas Holandesas">Antilhas Holandesas</option>
                                <option value="Antárctida">Antárctida</option>
                                <option value="Antígua e Barbuda">Antígua e Barbuda</option>
                                <option value="Argentina">Argentina</option>
                                <option value="Argélia">Argélia</option>
                                <option value="Armênia">Armênia</option>
                                <option value="Aruba">Aruba</option>
                                <option value="Arábia Saudita">Arábia Saudita</option>
                                <option value="Austrália">Austrália</option>
                                <option value="Áustria">Áustria</option>
                                <option value="Azerbaijão">Azerbaijão</option>
                                <option value="Bahamas">Bahamas</option>
                                <option value="Bahrein">Bahrein</option>
                                <option value="Bangladesh">Bangladesh</option>
                                <option value="Barbados">Barbados</option>
                                <option value="Belize">Belize</option>
                                <option value="Benim">Benim</option>
                                <option value="Bermudas">Bermudas</option>
                                <option value="Bielorrússia">Bielorrússia</option>
                                <option value="Bolívia">Bolívia</option>
                                <option value="Botswana">Botswana</option>
                                <option value="Brunei">Brunei</option>
                                <option value="Bulgária">Bulgária</option>
                                <option value="Burkina Faso">Burkina Faso</option>
                                <option value="Burundi">Burundi</option>
                                <option value="Butão">Butão</option>
                                <option value="Bélgica">Bélgica</option>
                                <option value="Bósnia e Herzegovina">Bósnia e Herzegovina</option>
                                <option value="Cabo Verde">Cabo Verde</option>
                                <option value="Camarões">Camarões</option>
                                <option value="Camboja">Camboja</option>
                                <option value="Canadá">Canadá</option>
                                <option value="Catar">Catar</option>
                                <option value="Cazaquistão">Cazaquistão</option>
                                <option value="Chade">Chade</option>
                                <option value="Chile">Chile</option>
                                <option value="China">China</option>
                                <option value="Chipre">Chipre</option>
                                <option value="Colômbia">Colômbia</option>
                                <option value="Comores">Comores</option>
                                <option value="Coreia do Norte">Coreia do Norte</option>
                                <option value="Coreia do Sul">Coreia do Sul</option>
                                <option value="Costa do Marfim">Costa do Marfim</option>
                                <option value="Costa Rica">Costa Rica</option>
                                <option value="Croácia">Croácia</option>
                                <option value="Cuba">Cuba</option>
                                <option value="Dinamarca">Dinamarca</option>
                                <option value="Djibouti">Djibouti</option>
                                <option value="Dominica">Dominica</option>
                                <option value="Egito">Egito</option>
                                <option value="El Salvador">El Salvador</option>
                                <option value="Emirados Árabes Unidos">Emirados Árabes Unidos</option>
                                <option value="Equador">Equador</option>
                                <option value="Eritreia">Eritreia</option>
                                <option value="Escócia">Escócia</option>
                                <option value="Eslováquia">Eslováquia</option>
                                <option value="Eslovênia">Eslovênia</option>
                                <option value="Espanha">Espanha</option>
                                <option value="Estados Federados da Micronésia">Estados Federados da Micronésia</option>
                                <option value="Estados Unidos">Estados Unidos</option>
                                <option value="Estônia">Estônia</option>
                                <option value="Etiópia">Etiópia</option>
                                <option value="Fiji">Fiji</option>
                                <option value="Filipinas">Filipinas</option>
                                <option value="Finlândia">Finlândia</option>
                                <option value="França">França</option>
                                <option value="Gabão">Gabão</option>
                                <option value="Gana">Gana</option>
                                <option value="Geórgia">Geórgia</option>
                                <option value="Gibraltar">Gibraltar</option>
                                <option value="Granada">Granada</option>
                                <option value="Gronelândia">Gronelândia</option>
                                <option value="Grécia">Grécia</option>
                                <option value="Guadalupe">Guadalupe</option>
                                <option value="Guam">Guam</option>
                                <option value="Guatemala">Guatemala</option>
                                <option value="Guernesei">Guernesei</option>
                                <option value="Guiana">Guiana</option>
                                <option value="Guiana Francesa">Guiana Francesa</option>
                                <option value="Guiné">Guiné</option>
                                <option value="Guiné Equatorial">Guiné Equatorial</option>
                                <option value="Guiné-Bissau">Guiné-Bissau</option>
                                <option value="Gâmbia">Gâmbia</option>
                                <option value="Haiti">Haiti</option>
                                <option value="Honduras">Honduras</option>
                                <option value="Hong Kong">Hong Kong</option>
                                <option value="Hungria">Hungria</option>
                                <option value="Ilha Bouvet">Ilha Bouvet</option>
                                <option value="Ilha de Man">Ilha de Man</option>
                                <option value="Ilha do Natal">Ilha do Natal</option>
                                <option value="Ilha Heard e Ilhas McDonald">Ilha Heard e Ilhas McDonald</option>
                                <option value="Ilha Norfolk">Ilha Norfolk</option>
                                <option value="Ilhas Cayman">Ilhas Cayman</option>
                                <option value="Ilhas Cocos (Keeling)">Ilhas Cocos (Keeling)</option>
                                <option value="Ilhas Cook">Ilhas Cook</option>
                                <option value="Ilhas Feroé">Ilhas Feroé</option>
                                <option value="Ilhas Geórgia do Sul e Sandwich do Sul">Ilhas Geórgia do Sul e Sandwich do Sul</option>
                                <option value="Ilhas Malvinas">Ilhas Malvinas</option>
                                <option value="Ilhas Marshall">Ilhas Marshall</option>
                                <option value="Ilhas Menores Distantes dos Estados Unidos">Ilhas Menores Distantes dos Estados Unidos</option>
                                <option value="Ilhas Salomão">Ilhas Salomão</option>
                                <option value="Ilhas Virgens Americanas">Ilhas Virgens Americanas</option>
                                <option value="Ilhas Virgens Britânicas">Ilhas Virgens Britânicas</option>
                                <option value="Ilhas Åland">Ilhas Åland</option>
                                <option value="Indonésia">Indonésia</option>
                                <option value="Inglaterra">Inglaterra</option>
                                <option value="Índia">Índia</option>
                                <option value="Iraque">Iraque</option>
                                <option value="Irlanda do Norte">Irlanda do Norte</option>
                                <option value="Irlanda">Irlanda</option>
                                <option value="Irã">Irã</option>
                                <option value="Islândia">Islândia</option>
                                <option value="Israel">Israel</option>
                                <option value="Itália">Itália</option>
                                <option value="Iêmen">Iêmen</option>
                                <option value="Jamaica">Jamaica</option>
                                <option value="Japão">Japão</option>
                                <option value="Jersey">Jersey</option>
                                <option value="Jordânia">Jordânia</option>
                                <option value="Kiribati">Kiribati</option>
                                <option value="Kuwait">Kuwait</option>
                                <option value="Laos">Laos</option>
                                <option value="Lesoto">Lesoto</option>
                                <option value="Letônia">Letônia</option>
                                <option value="Libéria">Libéria</option>
                                <option value="Liechtenstein">Liechtenstein</option>
                                <option value="Lituânia">Lituânia</option>
                                <option value="Luxemburgo">Luxemburgo</option>
                                <option value="Líbano">Líbano</option>
                                <option value="Líbia">Líbia</option>
                                <option value="Macau">Macau</option>
                                <option value="Macedônia">Macedônia</option>
                                <option value="Madagáscar">Madagáscar</option>
                                <option value="Malawi">Malawi</option>
                                <option value="Maldivas">Maldivas</option>
                                <option value="Mali">Mali</option>
                                <option value="Malta">Malta</option>
                                <option value="Malásia">Malásia</option>
                                <option value="Marianas Setentrionais">Marianas Setentrionais</option>
                                <option value="Marrocos">Marrocos</option>
                                <option value="Martinica">Martinica</option>
                                <option value="Mauritânia">Mauritânia</option>
                                <option value="Maurícia">Maurícia</option>
                                <option value="Mayotte">Mayotte</option>
                                <option value="Moldávia">Moldávia</option>
                                <option value="Mongólia">Mongólia</option>
                                <option value="Montenegro">Montenegro</option>
                                <option value="Montserrat">Montserrat</option>
                                <option value="Moçambique">Moçambique</option>
                                <option value="Myanmar">Myanmar</option>
                                <option value="México">México</option>
                                <option value="Mônaco">Mônaco</option>
                                <option value="Namíbia">Namíbia</option>
                                <option value="Nauru">Nauru</option>
                                <option value="Nepal">Nepal</option>
                                <option value="Nicarágua">Nicarágua</option>
                                <option value="Nigéria">Nigéria</option>
                                <option value="Niue">Niue</option>
                                <option value="Noruega">Noruega</option>
                                <option value="Nova Caledônia">Nova Caledônia</option>
                                <option value="Nova Zelândia">Nova Zelândia</option>
                                <option value="Níger">Níger</option>
                                <option value="Omã">Omã</option>
                                <option value="Palau">Palau</option>
                                <option value="Palestina">Palestina</option>
                                <option value="Panamá">Panamá</option>
                                <option value="Papua-Nova Guiné">Papua-Nova Guiné</option>
                                <option value="Paquistão">Paquistão</option>
                                <option value="Paraguai">Paraguai</option>
                                <option value="País de Gales">País de Gales</option>
                                <option value="Países Baixos">Países Baixos</option>
                                <option value="Peru">Peru</option>
                                <option value="Pitcairn">Pitcairn</option>
                                <option value="Polinésia Francesa">Polinésia Francesa</option>
                                <option value="Polônia">Polônia</option>
                                <option value="Porto Rico">Porto Rico</option>
                                <option value="Portugal">Portugal</option>
                                <option value="Quirguistão">Quirguistão</option>
                                <option value="Quênia">Quênia</option>
                                <option value="Reino Unido">Reino Unido</option>
                                <option value="República Centro-Africana">República Centro-Africana</option>
                                <option value="República Checa">República Checa</option>
                                <option value="República Democrática do Congo">República Democrática do Congo</option>
                                <option value="República do Congo">República do Congo</option>
                                <option value="República Dominicana">República Dominicana</option>
                                <option value="Reunião">Reunião</option>
                                <option value="Romênia">Romênia</option>
                                <option value="Ruanda">Ruanda</option>
                                <option value="Rússia">Rússia</option>
                                <option value="Saara Ocidental">Saara Ocidental</option>
                                <option value="Saint Martin">Saint Martin</option>
                                <option value="Saint-Barthélemy">Saint-Barthélemy</option>
                                <option value="Saint-Pierre e Miquelon">Saint-Pierre e Miquelon</option>
                                <option value="Samoa Americana">Samoa Americana</option>
                                <option value="Samoa">Samoa</option>
                                <option value="Santa Helena, Ascensão e Tristão da Cunha">Santa Helena, Ascensão e Tristão da Cunha</option>
                                <option value="Santa Lúcia">Santa Lúcia</option>
                                <option value="Senegal">Senegal</option>
                                <option value="Serra Leoa">Serra Leoa</option>
                                <option value="Seychelles">Seychelles</option>
                                <option value="Singapura">Singapura</option>
                                <option value="Somália">Somália</option>
                                <option value="Sri Lanka">Sri Lanka</option>
                                <option value="Suazilândia">Suazilândia</option>
                                <option value="Sudão">Sudão</option>
                                <option value="Suriname">Suriname</option>
                                <option value="Suécia">Suécia</option>
                                <option value="Suíça">Suíça</option>
                                <option value="Svalbard e Jan Mayen">Svalbard e Jan Mayen</option>
                                <option value="São Cristóvão e Nevis">São Cristóvão e Nevis</option>
                                <option value="São Marino">São Marino</option>
                                <option value="São Tomé e Príncipe">São Tomé e Príncipe</option>
                                <option value="São Vicente e Granadinas">São Vicente e Granadinas</option>
                                <option value="Sérvia">Sérvia</option>
                                <option value="Síria">Síria</option>
                                <option value="Tadjiquistão">Tadjiquistão</option>
                                <option value="Tailândia">Tailândia</option>
                                <option value="Taiwan">Taiwan</option>
                                <option value="Tanzânia">Tanzânia</option>
                                <option value="Terras Austrais e Antárticas Francesas">Terras Austrais e Antárticas Francesas</option>
                                <option value="Território Britânico do Oceano Índico">Território Britânico do Oceano Índico</option>
                                <option value="Timor-Leste">Timor-Leste</option>
                                <option value="Togo">Togo</option>
                                <option value="Tonga">Tonga</option>
                                <option value="Toquelau">Toquelau</option>
                                <option value="Trinidad e Tobago">Trinidad e Tobago</option>
                                <option value="Tunísia">Tunísia</option>
                                <option value="Turcas e Caicos">Turcas e Caicos</option>
                                <option value="Turquemenistão">Turquemenistão</option>
                                <option value="Turquia">Turquia</option>
                                <option value="Tuvalu">Tuvalu</option>
                                <option value="Ucrânia">Ucrânia</option>
                                <option value="Uganda">Uganda</option>
                                <option value="Uruguai">Uruguai</option>
                                <option value="Uzbequistão">Uzbequistão</option>
                                <option value="Vanuatu">Vanuatu</option>
                                <option value="Vaticano">Vaticano</option>
                                <option value="Venezuela">Venezuela</option>
                                <option value="Vietname">Vietname</option>
                                <option value="Wallis e Futuna">Wallis e Futuna</option>
                                <option value="Zimbabwe">Zimbabwe</option>
                                <option value="Zâmbia">Zâmbia</option>
                              </select>
                          </div>
                          <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                            <label>E-mail *</label>
                              <input
                                v-model="email"
                                type="text"
                                class="form-control"
                                maxlength="2"
                                autocomplete="no"
                                required
                              >
                          </div>
                          <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                            <label>Número de Inscricação na Prefeitura (CCM) *</label>
                              <input
                                type="number"
                                class="form-control"
                                autocomplete="no"
                                required
                              >
                          </div>
                          
                        </div>
                        <div class="card-header py-3">
                              <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                  <h6 class="m-0 font-weight-bold text-primary">
                                    Certificado Digital
                                  </h6>
                                </div>
                              </div>
                            </div>
                        <div class="row justify-content-between">
                            
                            <div class="col-sm-12 col-md-12 col-lg-12 mt-2">
                              <div class="form-group">
                                <uploader
                                  :file-status-text="statusText"
                                  class="uploader-example"
                                >
                                  <uploader-unsupport />
                                  <uploader-drop>
                                    <p class="aluno_font_color">
                                      Arraste o certificado aqui, ou
                                    </p>
                                    <uploader-btn :attrs="restrictArquivo">
                                      Clique aqui para selecionar
                                    </uploader-btn>
                                  </uploader-drop>
                                  <uploader-list />
                                </uploader>
                              </div>
                            </div>
                        </div>
                        <div class="row justify-content-between">
                          <div class="col-sm-12 col-md-12 col-lg-12 mt-2 text-center" >
                            <button
                              class="btn btn-primary mt-2 ml-2 blink_me"
                            >
                              <small>
                                <i
                                  class="fa fa-floppy-o"
                                  aria-hidden="true"
                                />
                                &nbsp;Salvar
                              </small>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              <!-- fd-app-meus-cursos -->
              <div class="mt-5 mb-5">
                <a
                  id="btn_voltar_modo_admin"
                  class="btn btn-red-hollow"
                  :href="
                    '/plataforma/' + $route.params.id_plataforma + '/tesouraria' 
                  "
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/tesouraria')"
                >
                  <b-icon-arrow-return-left />
                  Voltar
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modals -->

    <notifications
      group="foo"
      :close-on-click="false"
      position="bottom right"
    />
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";

export default {
  name: "HomeInternoTesourariaCadastroEmpresa",
  mixins: [methods],
  data: function () {
    return {
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      
      statusText: {
        success: "Sucesso",
        error: "Erro",
        uploading: "Fazendo upload...",
        paused: "Upload pausado",
        waiting: "Aguardando...",
      },
      
      restrictArquivo: {
        accept:
          "application/pdf,application/vnd.oasis.opendocument.text,application/msword,application/x-rar-compressed,application/vnd.ms-powerpoint,image/*",
      },
    };
  },
  mounted: function () {
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'tesouraria')
        .then(() => {
          if (
            this.$store.state.fastPlataformaModulos.modulo_secretaria
          ) {
            this.$store.state.fastCarregando = false;
          } else {
            this.$store.state.fastCarregando = false;
            this.exibeToasty("Módulo não disponível para sua plataforma", "error");
          }
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {},
};
</script>

<style scope>

</style>
